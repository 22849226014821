import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { FacebookProvider, Page } from 'react-facebook';

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  contentComponent,
  mainpitch,
  description,
  intro,
  content,
}) => {
  const PostContent = contentComponent || Content
  return (
    <div>
      <div className='columns is-desktop'>
        <div
          className="column full-width-image margin-top-0"
          style={{
            backgroundImage: `url(${
              !!image.childImageSharp ? image.childImageSharp.fluid.src : image
              })`,
            backgroundPosition: `center`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'
          }}
        >
        </div>
        {/* <div className='column'
          style={{
            display: 'flex',
            lineHeight: '1',
            justifyContent: 'center',
            alignItems: 'left',
            flexDirection: 'column',
          }}
        >
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              boxShadow:
                'rgb(225, 28, 0) 0.5rem 0px 0px, rgb(225, 28, 0) -0.5rem 0px 0px',
              backgroundColor: 'rgb(225, 28, 0)',
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
              margin: '0.75rem'
            }}
          >
            {title}
          </h1>
          <h3
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
            style={{
              boxShadow:
                'rgb(225, 28, 0) 0.5rem 0px 0px, rgb(225, 28, 0) -0.5rem 0px 0px',
              backgroundColor: 'rgb(225, 28, 0)',
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
              margin: '0.75rem'
            }}
          >
            {subheading}
          </h3>
        </div> */}
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className='columns'>
                    <div className="column is-6">
                      <div className="tile">
                        <h1 className="title">{mainpitch.title}</h1>
                      </div>
                      <div className="tile">
                        <h4 className="subtitle" style={{lineHeight: 1.5}}>{mainpitch.description}</h4>
                      </div>
                    </div>
                    <div className="column is-6">
                      <div className='has-text-centered'>
                        <div
                          style={{
                            width: '240px',
                            display: 'inline-block',
                            height: '246px'
                          }}
                        >
                          <PreviewCompatibleImage imageInfo={mainpitch.image} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Features gridItems={intro.blurbs} />
                <div className='columns'>
                  <div className="column is-6">
                    <div className='section'>
                      <h3 className="is-size-3">If you wish to support this campaign, donations can be mailed to:</h3>
                      <p>
                        Pothoff for Supervisor <br />
                      16748 Carmen Oaks Drive <br />
                      Durango, IA 52039
                    </p>
                      <p>or you may donate via PayPal: </p>
                      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_donations" />
                        <input type="hidden" name="business" value="FCV7LDFXFM3VE" />
                        <input type="hidden" name="item_name" value="Pothoff for the People" />
                        <input type="hidden" name="currency_code" value="USD" />
                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                        <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                      </form>
                    </div>
                  </div>
                  <div className='column is-6'>
                    <div className='section'>
                      <FacebookProvider appId="4771038199573785">
                        <Page href="https://www.facebook.com/Pothoff4Super/" tabs="events" />
                      </FacebookProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div >
  );
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  // subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        // subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        mainpitch {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 240, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
